<template>
	<div>
		<v-btn icon color="error" @click="deleteApplicationDialog = true">
			<v-icon>
				mdi-delete
			</v-icon>
		</v-btn>

		<v-dialog
			v-if="deleteApplicationDialog"
			v-model="deleteApplicationDialog"
			persistent
			max-width="500"
		>
			<v-card>
				<v-card-title class="text-h5 transparent-3">
					Delete Application
					<v-spacer></v-spacer>
					<v-btn color="error" icon @click="deleteApplicationDialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-row no-gutters class="justify-center ma-3">
					<v-col class="text-center" cols="12">
						All Related applications
					</v-col>
					<v-col
						class="text-center text-capitalize mt-2"
						v-for="(type, index) in application.related_applications"
						:key="index"
					>
						<b>{{ type.related_application_type }}</b>
						<br />
						{{ type.related_application_id }}
					</v-col>
				</v-row>
				<v-card-text class="my-3 mt-6">
					<v-form id="form" @submit.prevent v-model="isValidForm" ref="from">
						<v-checkbox
							v-model="deleteAllRelatedApplications"
							hide-details=""
							:label="
								`Delete All Other Related Applications: ${
									deleteAllRelatedApplications ? 'Yes' : 'No'
								}`
							"
						></v-checkbox>
					</v-form>
				</v-card-text>
				<v-card-actions class="transparent-2">
					<v-btn
						color="green darken-1"
						outlined
						block
						@click="deleteApplicationById()"
					>
						Delete Now
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {
			application: { required: true, default: null }
		},
		data() {
			return {
				isValidForm: false,
				deleteApplicationDialog: false,
				deleteAllRelatedApplications: true
			};
		},
		mounted() {},
		computed: {},
		methods: {
			refresh() {
				this.$emit("refresh");
			},
			async deleteApplicationById() {
				if (
					!confirm(
						`Bye deleting this application all related data will be deleted too.`
					) ||
					(this.deleteAllRelatedApplications &&
						!confirm(
							`All Related Applications to this Application will also be deleted.`
						)) ||
					!confirm(`Are you sure you want to delete this application?`)
				) {
					return false;
				}

				// return false;

				let data = {
					application_id: this.application.id,
					delete_all_related_applications: this.deleteAllRelatedApplications
						? 1
						: 0
				};

				return await this.post(
					`${this.baseUrl}/agent-portal/delete-application-by-id`,
					data,
					true
				).then(data => {
					if (data) {
						this.showMessageFromResponseDta(data);

						if (data?.code == 200) {
							this.$router.push({
								name:
									this.currentUserRole == "customer"
										? "dashboard-home"
										: "agent-portal"
							});
						}
					}
					return data;
				});
			}
		}
	};
</script>
