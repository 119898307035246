<template>
  <div>
    <!-- Loop through address detail forms -->
    <div v-for="(form, index) in address_detail_forms" :key="form.name + '-' + index">
      <v-card-title :key="form.name + '-heading'" class="px-0 mx-0" style="border-bottom: solid black 1px">
        <h3 class="font-weight-regular text-uppercase">
          <v-icon color="primary darken-1" :class="form.icon_class">{{ form.icon }}</v-icon>
          {{ form.title }}
        </h3>
        <v-spacer></v-spacer>

        <v-btn icon v-if="form.is_hideable" :color="form.is_showing ? '' : 'primary'"
          @click="form.is_showing = !form.is_showing">
          <v-icon>
            {{ form.is_showing ? "mdi-chevron-up" : "mdi-chevron-down" }}
          </v-icon>
        </v-btn>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              @click.stop="form.showInfo = !form.showInfo"
              class="ml-2"
              color="info"
            >
              mdi-information
            </v-icon>
          </template>
          <span>Information {{ form.title }}</span>
        </v-tooltip>
      </v-card-title>

      <!-- MaterialAlert for Click -->
      <MaterialAlert v-if="form.showInfo" icon="mdi-information" color="info" dismissible
        @click="form.showInfo = false">
        <div v-html="form.text"></div>
      </MaterialAlert>

      <!-- Conditional rendering based on form visibility -->
      <v-card-text v-if="(form.is_hideable && form.is_showing) || !form.is_hideable">
        <div class="ma-2 mt-6 show-form-labels">
          <AutoFormMaker @errorsList="(val) => parent.updateErrorsList(index, val)" :col="{ cols: 12 }"
            :model="formattedFormData" :schema="form_structure[form.name]" @click="handleFormInputClick"
            @input="combinedInputHandler">

            <!-- Drivers section -->
            <template #slot-top-array-form-base-drivers_info="{ idx }">
              <div class="d-flex justify-space-between pa-4 ma-2 bold title" :key="'driver-' + idx">
                <div>Driver {{ idx + 1 }}</div>
                <v-btn @click="removeDriver(idx)" icon small color="error">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </template>

            <!-- Vehicles section -->
            <template #slot-top-array-form-base-vehicles_info="{ idx }">
              <div class="d-flex justify-space-between pa-4 ma-2 bold title" :key="'vehicle-' + idx">
                <div>Vehicle {{ idx + 1 }}</div>
                <v-btn @click="removeVehicle(idx)" icon small color="error">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </template>

            <!-- Vehicle-Driver Assignment section inside the existing structure -->
            <template #slot-item-key-form-base-vehicle_driver_assignment>
              <v-row class="my-2">
                <v-col cols="12">
                  <v-simple-table>
                    <thead>
                      <tr>
                        <th class="text-left">Driver</th>
                        <th class="text-left" v-for="vehicle in form_data.vehicles_info" :key="vehicle.id">
                          {{ vehicle.year && vehicle.make && vehicle.model ? `${vehicle.year} ${vehicle.make} ${vehicle.model}` : "Select vehicle details" }}
                        </th>
                        <!-- Handle no vehicles -->
                        <th class="text-left" v-if="!form_data.vehicles_info.length">No vehicles added</th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- Handle no drivers -->
                      <tr v-if="!form_data.drivers_info.length">
                        <td colspan="100%">No drivers added</td>
                      </tr>
                      <tr v-else v-for="(driver, driverIndex) in form_data.drivers_info" :key="driver.id">
                        <td>{{ driver.first_name && driver.last_name ? `${driver.first_name} ${driver.last_name}` : "Select driver details" }}</td>
                        <td v-for="(vehicle, vehicleIndex) in form_data.vehicles_info" :key="vehicle.id + '-' + driver.id">
                          <v-text-field 
                            v-model="form_data.vehicle_driver_assignment[`${vehicleIndex}-${driverIndex}`]"
                            @input="vehicleDriverAssignmentCalculation(vehicleIndex, driverIndex)"
                            class="pa-0 my-4" 
                            type="number" 
                            suffix="%" 
                            clearable 
                            solo 
                            outlined 
                            dense>
                          </v-text-field>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-col>
              </v-row>
            </template>

            <template #slot-item-key-form-base-coverage_limits>
            </template>
          </AutoFormMaker>
        </div>
      </v-card-text>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AutoFormMaker from "@/components/forms/AutoFormMaker";
import axios from "axios";
import Helper from '@/plugins/helpers';
import auto_coverage_mapping_based_on_state from '@/plugins/auto_coverage_mapping_based_on_state.json'
import auto_carriers_pl_rater from "@/plugins/auto_carriers_pl_rater";
import MaterialAlert from '@/components/base/MaterialAlert.vue';
import { required, minLen, validEmail } from '@/plugins/validationRules';

const firstUpperCase = ({ data, value, obj }) => {
  if (value) return `${value.toString()[0].toUpperCase()}${value.toString().slice(1)}`;
  return value;
};

export default {
  props: {
    application_id: {
      required: true,
    },
    parent: {
      required: true,
    },
  },

  components: {
    AutoFormMaker,
    MaterialAlert,
  },

 data() {
    return {
      errorsList: [],
      isValidForm: false,
      address_detail_forms: [],
      years: [],
      makes: [],
      models: [],
      selectedState: 'FL',
      processedVins: new Set(),
      updatingVehicle: false
    };
  },

  computed: {
    ...mapState({
      form_data: (state) => state.application.form_data,
      application: (state) => state.application.application,
    }),

    vehicles: {
      get() {
        console.log("Computing vehicles");
        return this.form_data.vehicles_info.map((vehicle, index) => ({
          ...vehicle,
          id: vehicle.id || Date.now() + index,
          selectedYear: vehicle.year,
          selectedMake: vehicle.make,
          selectedModel: vehicle.model
        }));
      },
      set(newVehicles) {
        console.log("Setting vehicle:", newVehicles);
        newVehicles.forEach((vehicle, index) => {
          this.$store.commit('application/updateVehicle', { index, updatedVehicle: vehicle });
        });
      },
    },
   
    vehiclesInfo() {
      return this.$store.state.application.form_data.vehicles_info;
    },

    vehicleYears() {
      const currentYear = new Date().getFullYear();
      return Array.from({ length: 101 }, (_, i) => currentYear - i);
    },

    isMakeDisabled() {
      return (index) => !this.vehicles[index].selectedYear;
    },

    isModelDisabled() {
      return (index) => !this.vehicles[index].selectedMake;
    },

    formattedFormData: {
      get() {
        const data = {
          ...this.form_data,
          selectedYear: this.selectedYear,
          selectedMake: this.selectedMake
        };

        if (data.drivers_info) {
          data.drivers_info = data.drivers_info.map(driver => ({
            ...driver
          }));
        }
        if (data.vehicles_info) {
          data.vehicles_info = data.vehicles_info.map(vehicle => ({
            ...vehicle
          }));
        }
        return data;
      },
      set(newData) {
        this.$store.commit('application/updateFormData', {
          ...newData,
          selectedYear: undefined,
          selectedMake: undefined
        });

        this.selectedYear = newData.selectedYear;
        this.selectedMake = newData.selectedMake;
      },
    },

    application_details_form_selected_page: {
      set(val) {
        this.$store.state.application.application_details_form_selected_page = val;
      },
      get() {
        return this.$store.state.application.application_details_form_selected_page;
      },
    },

    form_structure() {
      const state = this.selectedState || this.extractStateFromAddress(this.form_data.address);
      const commonFields = auto_coverage_mapping_based_on_state.common_fields;
      const stateConfig = auto_coverage_mapping_based_on_state.states[state];

      const formStructure = {
        client_info: {
          first_name: {
            type: "text",
            name: "first_name",
            label: "First Name",
            ...this.$helpers.formFieldsBasicLayout,
            solo: false,
            toCtrl: firstUpperCase,
            fromCtrl: firstUpperCase,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
          },
          last_name: {
            type: "text",
            name: "last_name",
            label: "Last Name",
            ...this.$helpers.formFieldsBasicLayout,
            solo: false,
            toCtrl: firstUpperCase,
            fromCtrl: firstUpperCase,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
          },
          phone: {
            type: "tel",
            name: "phone",
            label: "Phone",
            ...this.$helpers.formFieldsBasicLayout,
            solo: false,
            mask: "(###) ###-####",
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
          },
          email: {
            type: "email",
            name: "email",
            label: "Email Address",
            ...this.$helpers.formFieldsBasicLayout,
            solo: false,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
          },
        },
        drivers_info: {
          add_driver: {
            type: "btn",
            name: "add_driver",
            label: "Add Driver",
            ...this.$helpers.formFieldsBasicLayout,
            col: 12,
          },
          drivers_info: {
            name: "drivers_info",
            type: "array",
            col: 12,
            schema: {
              first_name: {
                type: "text",
                name: "first_name",
                label: "First Name",
                ...this.$helpers.formFieldsBasicLayout,
                solo: false,
                toCtrl: firstUpperCase,
                fromCtrl: firstUpperCase,
              },
              last_name: {
                type: "text",
                name: "last_name",
                label: "Last Name",
                ...this.$helpers.formFieldsBasicLayout,
                solo: false,
                toCtrl: firstUpperCase,
                fromCtrl: firstUpperCase,
              },
              date_of_birth: {
                type: "text",
                ext: "date",
                name: "date_of_birth",
                label: "Date of birth",
                typeInt: "date",
                ...this.$helpers.formFieldsBasicLayout,
                solo: false,
              },
              gender: {
                type: "select",
                name: "gender",
                label: "Gender",
                ...this.$helpers.formFieldsBasicLayout,
                solo: false,
                items: ['Male', 'Female'],
                persistentPlaceholder: true,
                persistentHint: true,
              },
              marital_status: {
                type: "select",
                name: "marital_status",
                label: "Marital Status",
                ...this.$helpers.formFieldsBasicLayout,
                solo: false,
                items: ["Married", "Single"],
                persistentPlaceholder: true,
                persistentHint: true,
              },
              relationship: {
                type: "select",
                name: "relationship",
                label: "Relationship",
                ...this.$helpers.formFieldsBasicLayout,
                solo: false,
                items: ["Insured", "Spouse", "Children", "Sibling", "Parent"],
                persistentPlaceholder: true,
                persistentHint: true,
              },
              driver_license_no: {
                type: "text",
                name: "driver_license_no",
                label: "Driver's License number",
                ...this.$helpers.formFieldsBasicLayout,
                solo: false,
                rules: [],
              },
            },
          },
        },
        vehicle_info: {
          add_vehicle: {
            type: "btn",
            name: "add_vehicle",
            label: "Add Vehicle",
            ...this.$helpers.formFieldsBasicLayout,
            col: 12,
          },
          vehicles_info: {
            name: "vehicles_info",
            type: "array",
            col: 12,
            schema: {
              vin: {
                type: "text",
                name: "vin",
                label: "Vin",
                ...this.$helpers.formFieldsBasicLayout,
                solo: false,
                value: (vehicle) => vehicle.vin,
                onBlur: (index, vin) => this.onVinBlur(index, vin),
              },
              year: {
                type: "select",
                name: "selectedYear",
                label: "Year",
                ...this.$helpers.formFieldsBasicLayout,
                solo: false,
                items: this.vehicleYears,
                value: (vehicle) => vehicle.selectedYear,
                persistentPlaceholder: true,
                persistentHint: true,
              },
              make: {
                type: "select",
                name: "selectedMake",
                label: "Make",
                ...this.$helpers.formFieldsBasicLayout,
                solo: false,
                items: this.makes,
                value: (vehicle) => vehicle.selectedMake,
                persistentPlaceholder: true,
                persistentHint: true,
              },
              model: {
                type: "select",
                name: "selectedModel",
                label: "Model",
                ...this.$helpers.formFieldsBasicLayout,
                solo: false,
                items: this.models,
                value: (vehicle) => vehicle.selectedModel,
                persistentPlaceholder: true,
                persistentHint: true,
              },
              purchase_date: {
                type: "text",
                ext: "date",
                name: "purchase_date",
                label: "Purchase Date",
                typeInt: "date",
                value: (vehicle) => vehicle.purchaseDate,
                ...this.$helpers.formFieldsBasicLayout,
                solo: false,
              },
            },
          },
        },
        vehicle_assignment: {
          vehicle_driver_assignment: {
            type: "card",
            name: "vehicle_driver_assignment",
            col: 12,
            schema: {},
          },
        },
        current_prior_carrier: {
          current_prior_insurance: {
            type: "select",
            name: "current_prior_insurance",
            label: "Current Prior Insurance",
            clearable: false,
            outlined: true,
            color: "primary",
            class: "custom-style mt-1",
            elevation: 0,
            dense: true,
            items: ["Preferred", "Standard", "Non-Standard"],
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            persistentPlaceholder: true,
            persistentHint: true,
          },
          current_prior_company: {
            type: "select",
            name: "current_prior_company",
            label: "Current Prior Company",
            clearable: false,
            outlined: true,
            color: "primary",
            class: "custom-style mt-1",
            elevation: 0,
            dense: true,
            items: this.getCarrierOptions(),
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            persistentPlaceholder: true,
            persistentHint: true,
          },
          current_prior_limits: {
            type: "select",
            name: "current_prior_limits",
            label: "Current Prior Limits",
            clearable: false,
            outlined: true,
            color: "primary",
            class: "custom-style mt-1",
            elevation: 0,
            dense: true,
            items: [
              "25,000/50,000",
              "30,000/60,000",
              "50,000/100,000",
              "60,000/60,000",
              "100,000/100,000",
              "100,000/200,000",
              "100,000/300,000",
              "200,000/200,000",
              "200,000/300,000",
              "250,000/500,000",
              "300,000/300,000",
              "300,000/500,000",
              "300,000/1,000,000",
              "500,000/500,000",
              "500,000/1,000,000",
              "1,000,000/1,000,000",
              "1,000,000/2,000,000",
              "50,000",
              "60,000",
              "100,000",
              "200,000",
              "300,000",
              "500,000",
              "1,000,000",
            ],
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            persistentPlaceholder: true,
            persistentHint: true,
          },
          current_prior_exp_date: {
            type: "text",
            ext: "date",
            name: "current_prior_exp_date",
            label: "Current Prior Exp. Date",
            typeInt: "date",
            ...this.$helpers.formFieldsBasicLayout,
            solo: false,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
          },
          any_prior_lapse: {
            type: "btn-toggle",
            name: "any_prior_lapse",
            label: "Any Prior Lapse in Coverage in Last 3 Years",
            clearable: false,
            outlined: true,
            solo: true,
            text: true,
            hideDetails: false,
            class: "custom-style py-0 ",
            color: "primary",
            class: "",
            elevation: 0,
            options: [
              { value: 'Yes', label: 'Yes' },
              { value: 'No', label: 'No' }
            ],
            mandatory: true,
            col: { cols: 12, sm: 6, md: 6, lg: 6, xl: 6 },
          },
          current_prior_continuous_coverage: {
            type: "select",
            name: "current_prior_continuous_coverage",
            label: "Current Prior Continuous Coverage",
            clearable: false,
            outlined: true,
            color: "primary",
            class: "custom-style mt-1",
            elevation: 0,
            dense: true,
            items: [
              "6 Months", "12 Months", "18 Months", "24 Months", "30 Months", "36 Months", "42 Months", "48 Months", "60 Months", "72 Months", "84 Months", "96 Months", "108 Months", "120 Months"
            ],
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            persistentPlaceholder: true,
            persistentHint: true,
          },
        },
        coverages_limits: this.getCoveragesLimitsSchema(stateConfig, commonFields),
      };

      return formStructure;
    },
    
  },

  watch: {
    "$store.state.application.form_data.drivers_info"(newVal) {
      console.log("Drivers info changed:", newVal);
      this.makeDiverVehicleAssignment();
    },

    "$store.state.application.form_data.vehicles_info"(newVal) {
      console.log("Vehicles info changed:", newVal);
      this.makeDiverVehicleAssignment();
    },
    
    "$store.state.application.form_data": {
      handler(val) {
          console.log("Application form data changed:", val);

          // Updating client name ID based on current client data
          if (val && this.application && this.application.client) {
              let client = this.application.client;
              let firstName = val.first_name;
              let lastName = val.last_name;
              this.form_data.client_name_id = `${firstName ? firstName.toUpperCase() : ""} ${lastName ? lastName.toUpperCase() : ""}/${this.application.application_id}`;
              console.log("Updated client_name_id:", this.form_data.client_name_id);
          }
      },
      deep: true,
    },

    '$store.state.application.form_data.address': {
      handler(newAddress) {
        console.log("Address changed:", newAddress);
        this.fetchAndSetState();
      },
      immediate: true
    },

    '$store.state.application.form_data.vehicles_info': {
      handler(newValue) {
          if (this.updatingVehicle) {
              // Skip processing if the update is caused by the API response
              this.updatingVehicle = false;
              return;
          }
          // Check for VIN and possibly fetch details
          newValue.forEach((vehicle, index) => {
              if (vehicle.vin && vehicle.vin.length === 17 && !this.processedVins.has(vehicle.vin)) {
                  console.log(`Valid VIN detected at index ${index}: ${vehicle.vin}`);
                  this.atChangeInputHandler(vehicle.vin);
                  this.processedVins.add(vehicle.vin);
                  this.$store.dispatch('application/fetchVehicleDetailsFromVIN', { index, vin: vehicle.vin });
              }
          });
      },
      deep: true
    },

  },

  created() {
    this.setAddressDetailFormsSections();
    this.fetchAndSetState();
  },

  mounted() {
    this.$nextTick(function () {
      if (this.form_data?.drivers_info?.length == 0) {
        this.addNewDriver();
      }
    });
  },

  methods: {

    required,

    logFieldData(field) {
      console.log('Field Data:', field);
    },

    async loadMakes(year) {
      console.log("Loading makes for year:", year);
      try {
        const response = await axios.get(`${Helper.baseUrl()}/vehicles/makes/${year}`);
        console.log("Makes response:", response.data);
        // Sort the makes alphabetically before mapping
        this.makes = response.data
          .sort((a, b) => a.make.localeCompare(b.make)) // Sorting alphabetically
          .map(item => ({ text: item.make, value: item.make }));
      } catch (error) {
        console.error('Error loading makes:', error);
      }
    },

    async loadModels(year, make) {
      console.log("Loading models for year and make:", year, make);
      try {
        const response = await axios.get(`${Helper.baseUrl()}/vehicles/models/${year}/${make}`);
        console.log("Models response:", response.data);
        // Sort the models alphabetically before mapping
        this.models = response.data
          .sort((a, b) => a.ModelName.localeCompare(b.ModelName)) // Sorting alphabetically
          .map(item => ({ text: item.ModelName, value: item.ModelName }));
      } catch (error) {
        console.error('Error loading models:', error);
      }
    },


    async loadAllMakesAndModelsForVIN() {
      console.log("Loading all makes and models for VIN:");
        try {
            const response = await axios.get(`${Helper.baseUrl()}/vehicles/all-makes-and-models`);
            console.log("All makes and models response:", response.data);

            // Assuming response.data contains { makes: [], models: [] }
            this.makes = response.data.makes.map(make => ({ text: make, value: make }));
            this.models = response.data.models.map(model => ({ text: model, value: model }));
        } catch (error) {
            console.error('Error loading all makes and models:', error);
        }
    },


    async onYearChange(vehicle, index) {
        try {
            const makes = await this.loadMakes(vehicle.selectedYear);
            this.makes = makes;
            const updatedVehicle = { ...vehicle, selectedMake: null, selectedModel: null };
            this.updateVehicle(index, updatedVehicle);
        } catch (error) {
            console.error('Failed to load makes or update vehicle:', error);
        }
    },

    async onMakeChange(vehicle, index) {
        try {
            const models = await this.loadModels(vehicle.selectedYear, vehicle.selectedMake);
            this.models = models;
            const updatedVehicle = { ...vehicle, selectedModel: null };
            this.updateVehicle(index, updatedVehicle);
        } catch (error) {
            console.error('Failed to load models or update vehicle:', error);
        }
    },

    onVinBlur(index, vin) {
      console.log(`VIN input blurred at index ${index}: ${vin}`);
      if (vin && vin.length === 17 && !this.processedVins.has(vin)) { // standard VIN length
        console.log(`Valid VIN detected on blur at index ${index}: ${vin}`);
        this.processedVins.add(vin);
        this.$store.dispatch('application/fetchVehicleDetailsFromVIN', { index, vin });
      }
    },

    combinedInputHandler(value) { 
      this.handleFormInputClick(value);
      this.handleInput(value);
    },

    atChangeInputHandler(vin) {
    console.log("VIN Changed to: ", vin);
      this.vin = vin;
      
      // Check if `this.vin` has a value
      if (this.vin) {
        // If `this.vin` is not empty, call the method to load all makes and models
        this.loadAllMakesAndModelsForVIN();
      }
    },


    async handleInput(value) {
      console.log("Input changed", value);

      if (value.id.includes('form-base-vehicles_info')) {
        const index = parseInt(value.id.split('-')[3]);
        const key = value.key;
        const updatedVehicle = { ...this.form_data.vehicles_info[index], [key]: value.value };

        this.$store.commit('application/updateVehicle', {
          index,
          updatedVehicle
        });

        if (key === 'year') {
          console.log('change year');
          this.loadMakes(value.value);
        } else if (key === 'make') {
          console.log('change make');
          this.loadModels(this.selectedYear, value.value);
        }
      } else if (value.id.includes('form-base-drivers_info')) {
        const index = parseInt(value.id.split('-')[3]);
        const key = value.key;
        const updatedDriver = { ...this.form_data.drivers_info[index], [key]: value.value };

        this.$store.commit('application/updateDriver', {
          index,
          updatedDriver
        });
      }
    },

    async handleFormInputClick({ on, id, key, value, params }) {
      if (`${on} ${id} ${key} ${params.tag}` === "click form-base gender append-outer") {
        this.$nextTick(function () {
          this.$store.dispatch("application/refreshApplicationGender");
        });
      }
      if (`${on} ${id} ${key} ${value}` === "click form-base add_driver null") {
        this.addNewDriver();
      }
      if (`${on} ${id} ${key} ${value}` === "click form-base add_vehicle null") {
        this.addNewVehicle();
      }
    },

    addNewDriver() {
      const newDriver = {
        first_name: '',
        last_name: '',
        date_of_birth: '',
        gender: '',
        marital_status: '',
        relationship: '',
        driver_license_no: '',
      };
      this.$store.commit('application/addDriver', newDriver);
    },

    async updateDriver(index, updatedDriver) {
      this.$store.commit('application/updateDriver', { index, updatedDriver });
    },

    removeDriver(index) {
      this.$store.commit('application/removeDriver', index);
    },

    addNewVehicle() {
      const newVehicle = {
        vin: '',
        purchase_date: '',
      };
      this.$store.commit('application/addVehicle', newVehicle);
    },

    async updateVehicle(index, vehicleData) {
        try {
            if (index !== undefined && index >= 0) {
                this.$store.commit('application/updateVehicle', { index, updatedVehicle: vehicleData });
                console.log(`Vehicle at index ${index} updated successfully.`);
            } else {
                throw new Error("Invalid index for updating vehicle.");
            }
        } catch (error) {
            console.error('Failed to update vehicle:', error);
        }
    },

    removeVehicle(index) {
      this.$store.commit('application/removeVehicle', index);
    },
    
    makeDiverVehicleAssignment() {
      console.log("Initializing vehicle_driver_assignment...");
      this.form_data.vehicle_driver_assignment = this.form_data.vehicles_info.map((vehicle, vehicleIndex) => {
        let assignment = { total: 0 };
        this.form_data.drivers_info.forEach((driver, driverIndex) => {
          assignment[driverIndex] = null;
        });
        console.log(`Assignment for vehicle ${vehicleIndex}:`, assignment);
        return assignment;
      });
      console.log("Initialized vehicle_driver_assignment:", this.form_data.vehicle_driver_assignment);
    },

    vehicleDriverAssignmentCalculation(vehicleIndex, driverIndex) {
      console.log(`Calculating assignment for vehicle ${vehicleIndex} and driver ${driverIndex}...`);
      let vehicleAssignments = this.form_data.vehicle_driver_assignment;
      let currentInput = vehicleAssignments[vehicleIndex][driverIndex];
      
      // Enforce max input value of 100
      if (currentInput > 100) {
        vehicleAssignments[vehicleIndex][driverIndex] = 100;
        currentInput = 100;
      } else if (currentInput < 0) {
        vehicleAssignments[vehicleIndex][driverIndex] = 0;
        currentInput = 0;
      }

      // Calculate total percentage assigned to this vehicle by other drivers
      let totalAssigned = 0;
      Object.keys(vehicleAssignments[vehicleIndex]).forEach(key => {
        if (key !== 'total' && key !== driverIndex.toString()) {
          totalAssigned += Number(vehicleAssignments[vehicleIndex][key] || 0);
        }
      });

      // Adjust current input if total exceeds 100%
      if (totalAssigned + currentInput > 100) {
        vehicleAssignments[vehicleIndex][driverIndex] = 100 - totalAssigned;
      }

      // Update the total
      vehicleAssignments[vehicleIndex].total = totalAssigned + vehicleAssignments[vehicleIndex][driverIndex];

      console.log(`Updated assignment for vehicle ${vehicleIndex}:`, vehicleAssignments[vehicleIndex]);
      this.$nextTick(() => {
        this.form_data.vehicle_driver_assignment = {...vehicleAssignments};
        console.log("Updated vehicle_driver_assignment:", this.form_data.vehicle_driver_assignment);
      });
    },

    formatDateToUser(value) {
      if (!value) return '';
      const date = new Date(value);
      if (isNaN(date.getTime())) return '';
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const year = date.getFullYear();
      return `${month}-${day}-${year}`;
    },

    formatDateToBackend(value) {
      if (!value) return '';
      const [month, day, year] = value.split('-');
      if (!year || !month || !day) return '';
      return `${year}-${month}-${day}`;
    },

    setAddressDetailFormsSections() {
      this.address_detail_forms = [
        {
          title: "Client Information",
          icon: "mdi-account-circle-outline",
          name: "client_info",
          is_hideable: true,
          is_showing: true,
          showInfo: false,
          text: `
            <strong>Overview:</strong> Collect detailed and accurate information about the client.<br>
            <strong>Tips:</strong><br>
            - <strong>Accuracy:</strong> Stress the importance of accurate and complete information to ensure proper coverage and communication.<br>
            - <strong>Demographics:</strong> Explain how certain demographic factors, like marital status or homeownership, can affect rates and discounts.<br>
            <strong>Situational Use:</strong> For new clients, ensure that all information is collected accurately to provide the best quote possible and discuss how different factors might affect their premium.
          `,
        },
        {
          title: "Drivers Info",
          icon: "mdi-information-variant",
          name: "drivers_info",
          is_hideable: true,
          is_showing: false,
          showInfo: false,
          text: `
            <strong>Overview:</strong> Collect detailed information about all drivers who will be covered under the policy.<br>
            <strong>Tips:</strong><br>
            - <strong>Age and Experience:</strong> Emphasize the importance of accurate age and driving experience data. Older, more experienced drivers often qualify for lower premiums.<br>
            - <strong>Driving History:</strong> Highlight that a clean driving record can significantly reduce rates.<br>
            - <strong>Multi-Driver Discounts:</strong> Mention potential discounts for households with multiple drivers.<br>
            <strong>Situational Use:</strong> If the policyholder has teenage drivers, explain how adding them might affect the premium and discuss potential discounts or safe driving programs for young drivers.
          `,
        },
        {
          title: "Vehicle Info",
          icon: "mdi-information-variant",
          name: "vehicle_info",
          is_hideable: true,
          is_showing: false,
          showInfo: false,
          text: `
            <strong>Overview:</strong> Detailed information about the vehicles being insured.<br>
            <strong>Tips:</strong><br>
            - <strong>Vehicle Age:</strong> Explain how newer vehicles might have higher premiums due to repair costs but could qualify for discounts due to safety features.<br>
            - <strong>Safety Features:</strong> Highlight the benefits of vehicles with advanced safety features and how they can lower premiums.<br>
            - <strong>Usage:</strong> Discuss the impact of how the vehicle is used (e.g., personal use vs. business use) on the insurance premium.<br>
            <strong>Situational Use:</strong> For clients with high-value or classic cars, explain the importance of ensuring that these vehicles are adequately covered and discuss specialized policies.
          `,
        },
        {
          title: "Vehicle Assignment",
          icon: "mdi-information-variant",
          name: "vehicle_assignment",
          is_hideable: true,
          is_showing: false,
          showInfo: false,
          text: `
            <strong>Overview:</strong> Assign specific drivers to specific vehicles to better assess risk.<br>
            <strong>Tips:</strong><br>
            - <strong>Matching Drivers to Vehicles:</strong> Stress the importance of matching the primary driver to each vehicle accurately, as this affects the premium.<br>
            - <strong>Usage Patterns:</strong> Consider the driving habits and usage patterns of each driver when assigning vehicles.<br>
            <strong>Situational Use:</strong> For households with multiple vehicles and drivers, explain how the assignment can impact the overall policy premium and suggest strategies for minimizing costs.
          `,
        },
        {
          title: "Current Prior Carrier",
          icon: "mdi-text-box-check",
          name: "current_prior_carrier",
          is_hideable: true,
          is_showing: false,
          showInfo: false,
          text: `
            <strong>Overview:</strong> Information about the client’s current or previous insurance provider.<br>
            <strong>Tips:</strong><br>
            - <strong>Continuity of Coverage:</strong> Explain how maintaining continuous coverage can prevent rate increases.<br>
            - <strong>Claim History:</strong> Mention that a clean claim history can lead to lower premiums and potential discounts.<br>
            - <strong>Comparative Analysis:</strong> Encourage agents to use this information to compare coverage options and find the best value for the client.<br>
            <strong>Situational Use:</strong> If a client has been with their previous carrier for a long time, highlight loyalty discounts or potential savings by btn-toggleing to a new provider.
          `,
        },
        {
          title: "Coverages & Limits",
          icon: "mdi-text-box-check",
          name: "coverages_limits",
          is_hideable: true,
          is_showing: true,
          showInfo: false,
          text: `
            <strong>Overview:</strong> Different types of coverages available, such as liability, collision, and comprehensive.<br>
            <strong>Tips:</strong><br>
            - <strong>Minimum Requirements:</strong> Inform clients about the minimum coverage requirements in their state.<br>
            - <strong>Comprehensive Coverage:</strong> Explain the benefits of comprehensive coverage for protection against non-collision-related damage.<br>
            - <strong>Add-Ons:</strong> Highlight additional coverages like roadside assistance, rental reimbursement, and gap insurance.<br>
            <strong>Situational Use:</strong> For clients unsure about what coverages to choose, provide examples of common scenarios where each type of coverage is beneficial, such as collision coverage for accident repairs or comprehensive coverage for theft or natural disasters.
          `,
        },
      ];
    },

    getCarrierOptions() {
      return auto_carriers_pl_rater.map(carrier => ({
        value: carrier.value,
        text: carrier.label,
      }));
    },

    extractStateFromAddress(address) {
      const stateRegex = /(?:\s|^)(AL|AK|AS|AZ|AR|CA|CO|CT|DE|DC|FL|GA|GU|HI|ID|IL|IN|IA|KS|KY|LA|ME|MD|MA|MI|MN|MS|MO|MT|NE|NV|NH|NJ|NM|NY|NC|ND|MP|OH|OK|OR|PA|PR|RI|SC|SD|TN|TX|UT|VT|VI|VA|WA|WV|WI|WY)(?=\s|$)/;
      const match = address.match(stateRegex);
      return match ? match[1] : null;
    },

    fetchAndSetState() {
      const address = this.form_data.address;
      const state = this.extractStateFromAddress(address);
      this.selectedState = state;
      console.log(`Extracted state: ${state}`);
    },

    getCoveragesLimitsSchema(stateConfig, commonFields) {
      let schema = {};

      // Add common fields to schema
      for (const field in commonFields) {
        schema[field] = { ...commonFields[field] };
      }

      // Add options and properties to common fields based on state
      if (stateConfig && stateConfig.common_fields) {
        for (const field in stateConfig.common_fields) {
          schema[field] = { ...stateConfig.common_fields[field] };
        }
      }

      // Add variable fields to schema
      if (stateConfig && stateConfig.variable_fields) {
        for (const field in stateConfig.variable_fields) {
          schema[field] = { ...stateConfig.variable_fields[field] };
        }
      }

      console.log("Final schema:", schema);
      return schema;
    },

    getFormSchema(formName) {
      console.log("getFormSchema called with formName:", formName);
      let formSchema;
      if (formName === 'coverage_limits') {
        formSchema = this.form_structure.coverages_limits;
      } else {
        formSchema = this.form_structure[formName];
      }
      console.log(`Form schema for ${formName}:`, formSchema);
      return formSchema;
    },

  }
};
</script>

<style lang="scss">
.text-field-left-border::before {
  position: absolute;
}

.show-form-labels {

  .key-form-base-drivers_info.prop-drivers_info,
  .key-form-base-vehicles_info.prop-vehicles_info { 
    display: flex !important;
    flex-direction: row { 
      width: 100%;
    }
  }

  #form-base {
    padding-top: 10px !important;

    .item {
      &.type-form-base-btn {
        padding-top: 0 !important;
      }

      * {
        background-color: transparent !important;
      }

      fieldset legend {
        display: none !important;
      }

      .v-label,
      .v-label--active {
        font-size: 1rem !important;
        color: #343434 !important;
        display: inline-block !important;
        transform: translateX(-10px) translateY(-37px) scale(1) !important;
      }

      .v-text-field__details {
        display: inline-block !important;
      }
    }

    .driver-icon {
      margin-right: 8px;
    }

    .vehicle-icon {
      margin-right: 8px;
    }
  }
}

.form-label {
  margin-bottom: 8px;
  font-weight: bold;
  display: block;
  /* Ensure the label is displayed as a block element */
  color: #000;
  /* Set a default color */
}
</style>
