<template>
  <div>
    <template v-for="(form, index) in address_detail_forms">
      <v-card-title
        :key="index + form.name + '-heading'"
        class="px-0 mx-0"
        style="border-bottom: solid black 1px"
      >
        <h3 class="font-weight-regular text-uppercase">
          <v-icon color="primary darken-1" :class="form.icon_class">{{
            form.icon
          }}</v-icon>
          {{ form.title }}
        </h3>
        <v-spacer></v-spacer>
        <v-btn
          icon
          v-if="form.is_hideable"
          :color="form.is_showing ? '' : 'primary'"
          @click="
            form.is_showing
              ? (form.is_showing = false)
              : (form.is_showing = true)
          "
        >
          <v-icon>
            {{
              form.is_showing
                ? "mdi-arrow-up-drop-circle"
                : "mdi-arrow-down-drop-circle"
            }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text
        :key="index + form.name + '-form'"
        v-if="
          (form.is_hideable && form.is_showing) || form.is_hideable == false
        "
      >
        <div class="ma-2 mt-6">
          <FormMaker
            @errorsList="(val) => parent.updateErrorsList(index, val)"
            :col="{ cols: 12 }"
            :model="form_data"
            :schema="form_structure[form.name]"
            @click="handleFormInputClick"
            @input="handleFormInputClick"
          />
        </div>
      </v-card-text>
    </template>
  </div>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";
import FormMaker from "@/components/FormMaker";
import {
  required,
  validEmail,
  minLen,
  minNumber,
  maxNumber,
  notInInvalidValues,
  lessThenEqual,
} from "@/plugins/validationRules.js";
import floodZones from "@/assets/floodZonesList";
import states from "@/assets/statesList";
import months from "@/assets/monthsList";
import breedData from "@/plugins/breeds.json";
const formatCurrencyCtrl = ({ data, newVal, value, obj }) => {
  if (value && formatAsCurrency(value) != value.toString())
    return (data[obj.key] = formatAsCurrency(value));
  return value;
};
const firstUpperCase = ({ data, value, obj }) => {
  if (value)
    return `${value.toString()[0].toUpperCase()}${value.toString().slice(1)}`;
  return value;
};
const formatAsCurrency = (value) => {
  let newVal = 0;
  if (value) {
    // console.log(Number(value.toString().replace(/[^0-9\.]/g, "")));
    newVal = Number.parseInt(value.toString().replace(/[^0-9\.]/g, ""))
      .toString()
      .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  }
  return newVal;
};

export default {
  props: {
    application_id: {
      required: true,
    },
    parent: {
      required: true,
    },
  },
  components: {
    FormMaker,
  },
  data() {
    return {
      floodZones,
      states,
      months,
      errorsList: [],
      isValidForm: false,
      address_detail_forms: [],
    };
  },
  computed: {
    ...mapState({
      form_data: (state) => state.application.form_data,
      application: (state) => state.application.application,
    }),
    application_details_form_selected_page: {
      set(val) {
        this.$store.state.application.application_details_form_selected_page =
          val;
      },
      get() {
        return this.$store.state.application
          .application_details_form_selected_page;
      },
    },
    isApplicationLaunchAble() {
      return (
        this.application &&
        this.application.payload &&
        this.application.payload.status != "launched" &&
        this.application.payload.status != "complete"
      );
    },
    form_structure() {
      return {
        client_info: {
          first_name: {
            type: "text",
            name: "first_name",
            id: "first_name",
            label: "First Name",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            class: "custom-style py-0 my-0",
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            toCtrl: firstUpperCase,
            fromCtrl: firstUpperCase,
            rules: [required()],
          },
          last_name: {
            type: "text",
            name: "last_name",
            label: "Last Name",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            class: "custom-style py-0 my-0",
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            toCtrl: firstUpperCase,
            fromCtrl: firstUpperCase,
            rules: [required()],
          },
          phone: {
            type: "tel",
            name: "phone",
            class: "custom-style",
            label: "Phone",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            mask: "(###) ###-####",
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [minLen(14, "10 Digits required.")],
          },
          email: {
            type: "email",
            name: "email",
            class: "custom-style",
            label: "Email Address",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [validEmail()],
          },
        },
        pet_info: {
          pet_name: {
            type: "text",
            name: "pet_name",
            id: "pet_name",
            label: "Name",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            class: "custom-style py-0 my-0",
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            toCtrl: firstUpperCase,
            fromCtrl: firstUpperCase,
            rules: [required()],
          },
          pet_age: {
            type: "select",
            name: "pet_age",
            class: "custom-style",
            label: "Age",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            multiple: false,
            color: "primary",
            elevation: 0,
            // value: this.form_data.state,
            items: [
              "6 weeks to 11 months",
              "1 year",
              "2 years",
              "3 years",
              "4 years",
              "5 years",
              "6 years",
              "7 years",
            ],
            menuProps: {
              overflowY: true,
              offsetY: true,
              maxHeight: 304,
            },
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [required()],
          },
          pet_gender: {
            type: "btn-toggle",
            name: "pet_gender",
            label: "Gender",

            clearable: false,
            outlined: true,
            solo: true,
            text: true,
            hideDetails: false,
            class: "custom-style py-0 pt-4",
            color: "primary",
            class: "",
            elevation: 0,
            options: ["Male", "Female"],
            // offset: { offset: "1" },
            spacer: false,
            dense: true,
            col: { cols: 12, sm: 6, md: 6, lg: 6, xl: 6 },
            mandatory: false,
            rules: [required],
          },
          pet_species: {
            type: "btn-toggle",
            name: "pet_species",
            label: "Species",

            clearable: false,
            outlined: true,
            solo: true,
            text: true,
            hideDetails: false,
            class: "custom-style py-0 pt-4",
            color: "primary",
            class: "",
            elevation: 0,
            options: ["Dog", "Cat"],
            // offset: { offset: "1" },
            spacer: false,
            dense: true,
            col: { cols: 12, sm: 6, md: 6, lg: 6, xl: 6 },
            mandatory: false,
            rules: [required],
          },

          pet_breed: {
            type: "select",
            name: "pet_breed",
            class: "custom-style",
            label: "Breed",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            multiple: false,
            color: "primary",
            elevation: 0,
            items:
              this.form_data.pet_species === "Dog"
                ? breedData.dogBreeds
                : breedData.catBreeds,
            menuProps: {
              overflowY: true,
              offsetY: true,
              maxHeight: 304,
            },
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [required()],
          },

          pet_spay_or_neutered: {
            type: "btn-toggle",
            name: "pet_spay_or_neutered",
            label: "Spay Or Neutered",

            clearable: false,
            outlined: true,
            solo: true,
            text: true,
            hideDetails: false,
            class: "custom-style py-0 pt-4",
            color: "primary",
            class: "",
            elevation: 0,
            options: ["Spay", "Neutered"],
            // offset: { offset: "1" },
            spacer: false,
            dense: true,
            col: { cols: 12, sm: 6, md: 6, lg: 6, xl: 6 },
            mandatory: false,
            rules: [required],
          },
        },
      };
    },
  },
  watch: {
    "$store.state.application.form_data": {
      handler: function (val, oldVal) {
        if (val && this.application && this.application.client) {
          // this.$refs.form.validate();
          let client = this.application.client;
          let firstName = val.first_name;
          let lastName = val.last_name;

          this.form_data.client_name_id = `${
            firstName ? firstName.toString().toUpperCase() : ""
          } ${lastName ? lastName.toString().toUpperCase() : ""}/${
            this.application.application_id
          }`;

          if (
            val.occupancy_type != oldVal.occupancy_type &&
            val.occupancy_type == "Townhouse/Rowhouse"
          ) {
            this.form_data.townhouse = "Yes";
          } else {
            this.form_data.townhouse = "No";
          }

          // if (val.condo_unit_number != val.address2) {
          // 	this.form_data.address2 = val.condo_unit_number;
          // }

          if (
            val.year_build != val.roof_year &&
            Number.parseInt(
              this.$moment().subtract(10, "years").format("YYYY")
            ) <= Number.parseInt(val.year_build)
          ) {
            this.form_data.roof_year = val.year_build;
          }

          this.generateFoundationTypeComputed();
          this.generateStairsLeadingToFrontDoorComputed();
          this.generateElevationDifference();
        }
      },
      deep: true,
    },
  },
  created() {
    this.setAddressDetailFormsSections();
    // this.form_data.searched_address
    // 	? null
    // 	: this.$router.push({ name: "agent-portal" });
    // this.form_data.searched_address
    // 	? (this.$store.state.selected_agent_id = this.$store.state.application.agent.id)
    // 	: null;
  },
  mounted() {},
  methods: {
    setAddressDetailFormsSections() {
      this.address_detail_forms = [
        {
          title: "Client Information",
          icon: "mdi-account-circle-outline",
          name: "client_info",
          is_hideable: false,
        },
        {
          title: "Pet Information",
          icon: "mdi-form-dropdown",
          name: "pet_info",
          is_hideable: false,
          // is_showing: false,
          // hide: true
        },
      ];
    },
    generateFoundationTypeComputed() {
      if (["Stilts Pilings"].includes(this.form_data.foundation_type)) {
        this.form_data.foundation_type_computed = `${this.form_data.foundation_type} - ${this.form_data.stilts_pilings}`;
      } else if (["Garage"].includes(this.form_data.foundation_type)) {
        this.form_data.foundation_type_computed = `${
          this.form_data.foundation_type
        } ${this.mapYesNoToFinishedUnfinished(this.form_data.garage_finished)}`;
      } else if (["Basement"].includes(this.form_data.foundation_type)) {
        this.form_data.foundation_type_computed = `${
          this.form_data.basement_type
        } - ${this.mapYesNoToFinishedUnfinished(
          this.form_data.basement_finished
        )}`;
      } else {
        this.form_data.foundation_type_computed =
          this.form_data.foundation_type;
      }
      // console.log(this.form_data.foundation_type_computed);
    },
    generateStairsLeadingToFrontDoorComputed() {
      let value = 0;
      if (["Crawlspace"].includes(this.form_data.foundation_type_computed)) {
        value = 3;
      } else if (
        [
          "Stilts Pilings - Open",
          "Stilts Pilings - Solid Enclosure - Unfinished",
          "Garage Unfinished",
        ].includes(this.form_data.foundation_type_computed)
      ) {
        value = 16;
      } else {
        value = this.form_data.stairs_leading_to_front_door;
      }
      this.form_data.stairs_leading_to_front_door_computed = value;
      // console.log(this.form_data.foundation_type_computed);
    },
    generateElevationDifference: _.debounce(
      async function () {
        try {
          // 1. Slab + 0 but
          // Each “stair” = +.5
          // Example: 3 “stairs”: +1.5
          // 2. Crawlspace:   +1.5
          // 3. Stilts Pilings - Open: +8
          // 4. Stilts Pilings - Solid Enclosure - Unfinished: +8
          // 5. Stilts Pilings - Solid Enclosure - Living Space: +0
          // 6. Garage Unfinished +8
          // 7. Garage Finished +0
          // 8. Full Basement - Finished: -7
          // 9. Full Basement - Unfinished: -7
          // 10. Walkout Basement - Finished -7
          // 11. Walkout Basement - Unfinished -7
          // 12. Subgrade Crawlspace -1
          // 13. Split Level +0
          let diff = Number.parseFloat(this.form_data.elevation_difference);
          if (["Slab"].includes(this.form_data.foundation_type_computed)) {
            this.form_data.elevation_difference_computed = eval(
              diff +
                (this.form_data.stairs_leading_to_front_door
                  ? this.form_data.stairs_leading_to_front_door * 0.5
                  : 0)
            );
          } else if (
            ["Crawlspace"].includes(this.form_data.foundation_type_computed)
          ) {
            this.form_data.elevation_difference_computed = eval(
              diff + 1.5
            ).toFixed(2);
          } else if (
            [
              "Full Basement - Finished",
              "Full Basement - Unfinished",
              "Walkout Basement - Finished",
              "Walkout Basement - Unfinished",
            ].includes(this.form_data.foundation_type_computed)
          ) {
            this.form_data.elevation_difference_computed = eval(
              diff - 7
            ).toFixed(2);
          } else if (
            [
              "Garage Unfinished",
              "Stilts Pilings - Open",
              "Stilts Pilings - Solid Enclosure - Unfinished",
            ].includes(this.form_data.foundation_type_computed)
          ) {
            this.form_data.elevation_difference_computed = eval(
              diff + 8
            ).toFixed(2);
          } else if (
            ["Subgrade Crawlspace"].includes(
              this.form_data.foundation_type_computed
            )
          ) {
            this.form_data.elevation_difference_computed = eval(
              diff - 1
            ).toFixed(2);
          }
          // console.log(this.form_data.elevation_difference_computed);
        } catch (err) {
          console.log("error in generateElevationDifference code", err);
        }
      },
      50,
      {
        leading: true,
        trailing: false,
      }
    ),
    async handleFormInputClick({
      on,
      id,
      key,
      value,
      params,
      obj,
      data,
      schema,
      parent,
      index,
      event,
    }) {
      // console.log(
      // 	on,
      // 	id,
      // 	key,
      // 	value,
      // 	params,
      // 	obj,
      // 	data,
      // 	schema,
      // 	parent,
      // 	index,
      // 	event
      // );
      if (
        `${on} ${id} ${key} ${params.tag}` ==
        "click form-base gender append-outer"
      ) {
        this.$nextTick(function () {
          this.$store.dispatch("application/refreshApplicationGender");
        });
      }

      if (`${on} ${id} ${key} ${value}` == "input form-base smoker Yes") {
        this.$nextTick(function () {
          this.form_data.usages.push({
            category: null,
            status: null,
            last_use_date: null,
            frequency: null,
          });
        });
      }

      if (`${on} ${id} ${key} ${value}` == "input form-base smoker No") {
        this.$nextTick(function () {
          this.form_data.usages = [];
        });
      }

      if (
        on + "," + id + "," + key + "," + params.tag ==
        "click,form-base,distance_to_coast,append-outer"
      ) {
        this.openPropertyInFloodFactor();
      }
    },
  },
};
</script>

<style lang="scss">
.text-field-left-border::before {
  position: absolute;
}
</style>
